(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/balance-bar/assets/javascripts/balance-bar.js') >= 0) return;  svs.modules.push('/components/lb-ui/balance-bar/assets/javascripts/balance-bar.js');

'use strict';

svs.lbUi = svs.lbUi || {};
class BalanceBar {
  constructor() {
    const $body = $('body');
    const $topContentWrapper = svs.components.header.getFixedTopContentWrapper();
    const $corporateInfoWrapper = $('.js-corporate-info-bar');
    const corporateInformationPreventAnimationClass = 'corporate-info-animation-disabled';
    this.showAccountBalance = false;
    if (svs.core.detect.breakpoint.greaterThan('md')) {
      $body.addClass(corporateInformationPreventAnimationClass);
    } else {
      svs.core.detect.breakpoint.on('change', () => {
        if (svs.core.detect.breakpoint.greaterThan('md')) {
          $body.addClass(corporateInformationPreventAnimationClass);
        }
      });
    }
    const breakPointListener = () => {
      if (svs.core.detect.breakpoint.lessThan('md')) {
        $corporateInfoWrapper.addClass('js-fixed-top-content');
        $topContentWrapper.removeClass('js-fixed-top-content');
        svs.components.header.setFixedTopContentWrapper($corporateInfoWrapper);
      } else {
        $corporateInfoWrapper.removeClass('js-fixed-top-content');
        $topContentWrapper.addClass('js-fixed-top-content');
        svs.components.header.setFixedTopContentWrapper($topContentWrapper);
      }
    };
    svs.core.detect.breakpoint.on('change', breakPointListener);
    breakPointListener();
    if (svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
      var _document$querySelect;
      const rendered = svs.lb_ui && svs.lb_ui.balance_bar && svs.lb_ui.balance_bar.data && svs.lb_ui.balance_bar.data.account_balance_rendered;
      this.$sessionTimeContainer = $('.js-session-time-header');
      this.$accountBalanceContainer = $('.js-balance-toggle');
      this.$accountBalanceContent = $('.js-balance-content');
      this.renderLoggedInTime(svs.components.session_time.get());
      this.noAccountBalance = Boolean(svs.core.template.getConfig().noAccountBalance);
      this.hideBalanceBarInXsSm = Boolean(svs.core.template.getConfig().hideBalanceBarInXsSm);
      svs.events.subscribe('/components/session_time/sessionTimeM', sessionTime => this.renderLoggedInTime(sessionTime));
      $('.js-gaming-test-link').on('click', e => {
        e.preventDefault();
        const locationData = svs.utils.url.getLocation();
        const [targetUrl, params] = svs.core.urlMapping.get('mygamingSelfTestDirect').split('#');
        const filterdParams = params ? "#".concat(params) : '';
        if (locationData.pathname === targetUrl) {
          const [rUrl, query] = this.getQueryString();
          svs.utils.url.navigate("".concat(targetUrl).concat(rUrl).concat(query).concat(filterdParams));
        } else {
          svs.utils.url.navigate("".concat(targetUrl, "?returnUrl=").concat(encodeURIComponent(svs.utils.url.currentPathEncodedParams())).concat(filterdParams));
        }
      });
      $('.js-corporate-info-pause24-link').on('click', e => this.onPause24Click(e));
      this.$accountBalanceContainer.on('click', e => this.toggleAccountBalance(e));
      this.$accountBalanceContainer.on('keydown', e => {
        if (e.keyCode === 13 || e.keyCode === 32) {
          e.preventDefault();
          this.toggleAccountBalance(e);
        }
      });
      svs.components.account_balance.on('change', balance => {
        if (this.showAccountBalance && !this.noAccountBalance) {
          this.renderAccountBalance(balance);
        }
      });
      svs.core.template.on('configUpdate', e => {
        let propIndex = e.changed.indexOf('noAccountBalance');
        if (~propIndex) {
          this.noAccountBalance = Boolean(e.config.noAccountBalance);
          if (this.noAccountBalance && this.showAccountBalance) {
            this.renderAccountBalance();
          } else if (!this.noAccountBalance && this.showAccountBalance) {
            svs.components.account_balance.get(true, this.renderAccountBalance, this);
          }
        }
        propIndex = e.changed.indexOf('hideBalanceBarInXsSm');
        if (~propIndex) {
          if (this.hideBalanceBarInXsSm !== Boolean(e.config.hideBalanceBarInXsSm)) {
            this.hideBalanceBarInXsSm = !this.hideBalanceBarInXsSm;
            if (this.hideBalanceBarInXsSm) {
              $body.addClass('hide-balance-bar');
            } else {
              $body.removeClass('hide-balance-bar');
            }
            svs.components.header.updateCachedHeights();
          }
        }
        propIndex = e.changed.indexOf('hideBalanceBar');
        if (~propIndex || ~e.changed.indexOf('hideMenu')) {
          const shouldHideBalanceBar = e.config.hideBalanceBar || e.config.hideMenu;
          if (this.hideBalanceBar !== shouldHideBalanceBar) {
            this.hideBalanceBar = !this.hideBalanceBar;
            if (this.hideBalanceBar) {
              $body.addClass('hide-balance-bar-all-over');
            } else {
              $body.removeClass('hide-balance-bar-all-over');
            }
            svs.components.header.updateCachedHeights();
          }
        }
      });
      svs.core.userSession.get('showAccountBalance', (error, data) => {
        this.showAccountBalance = Boolean(data);
        if (!rendered) {
          if (this.showAccountBalance) {
            svs.components.account_balance.get(true, this.renderAccountBalance, this);
          } else {
            this.renderAccountBalance();
          }
        }
      });
      (_document$querySelect = document.querySelector('.js-balance-bar-deposit')) === null || _document$querySelect === void 0 || _document$querySelect.addEventListener('click', e => {
        e.preventDefault();
        svs.components.analytics.trackEvent({
          category: 'Navigation',
          action: 'top_bar',
          opt_label: 'Insättning'
        }, () => svs.utils.url.navigate(svs.core.urlMapping.get('transactionsHome')));
      });
    }
  }
  getQueryString() {
    const returnUrl = "?returnUrl=".concat(encodeURIComponent(svs.core.urlMapping.get('mygamingSelfTest')));
    let queryString = '';
    const keys = Object.keys(svs.utils.url.queryString);
    if (keys.includes('returnUrl')) {
      keys.splice(keys.findIndex(s => s === 'returnUrl'), 1);
    }
    if (keys.length > 0) {
      keys.forEach(e => {
        queryString += "&".concat(e, "=").concat(encodeURIComponent(svs.utils.url.queryString[e]));
      });
    }
    return [returnUrl, queryString];
  }
  renderLoggedInTime() {
    let timeInMinutes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    let timeToDisplay;
    if (timeInMinutes < 60) {
      timeToDisplay = "".concat(timeInMinutes, " min");
    } else if (timeInMinutes < 24 * 60) {
      timeToDisplay = "".concat(Math.floor(timeInMinutes / 60), " tim ").concat(timeInMinutes % 60, " min");
    } else if (timeInMinutes < 48 * 60) {
      timeToDisplay = '1 dag';
    } else {
      timeToDisplay = "".concat(Math.ceil(timeInMinutes / (24 * 60)), " dagar");
    }
    this.$sessionTimeContainer.html(timeToDisplay);
    this.$sessionTimeContainer[0].setAttribute('aria-label', "Inloggad tid ".concat(timeToDisplay));
  }
  onPause24Click(e) {
    e.preventDefault();
    if (svs.accountservices && svs.accountservices.pause24) {
      svs.accountservices.pause24.show();
    } else {
      const loader = new svs.components.Loader();
      loader.show(300, 'inverted');
      svs.core.module.load('/cl/accountservices/pause24', null, error => {
        loader.hide();
        if (error && error.statusText === 'error') {
          svs.core.module.load('/cl/components/dialog', null, () => {
            svs.components.dialog.api.add(new svs.components.dialog.Confirm({
              actions: [{
                title: 'Okej'
              }],
              area: svs.components.dialog.area.POPUP,
              branding: svs.components.dialog.branding.SPORT,
              message: [{
                text: 'För tillfället upplever vi tekniska problem. Prova igen om en stund.'
              }],
              title: 'Något gick visst fel',
              type: svs.components.dialog.type.ERROR
            }));
          });
        } else {
          svs.accountservices.pause24.show();
        }
      }, false);
    }
  }
  toggleAccountBalance(e) {
    if (svs.core.template.getConfig().noAccountBalance) {
      const dialog = new svs.components.dialog.Contextual({
        branding: svs.components.dialog.branding.SPORT,
        attachTo: $(e.currentTarget),
        zIndex: 1100,
        title: ' ',
        message: [{
          type: svs.components.dialog.message.TEXT,
          text: 'Eftersom ditt saldo kontinuerligt visas i spelläget går det inte att visa saldot här.'
        }]
      });
      svs.components.dialog.api.add(dialog);
      e.stopPropagation();
    } else if (svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
      this.showAccountBalance = !this.showAccountBalance;
      svs.core.userSession.set('showAccountBalance', this.showAccountBalance, () => {
        if (this.showAccountBalance) {
          svs.components.account_balance.get(true, this.renderAccountBalance, this);
        } else {
          this.renderAccountBalance();
        }
      });
    } else {
      this.renderAccountBalance();
    }
  }
  renderAccountBalance(balance) {
    if (balance === undefined) {
      this.$accountBalanceContent.html('Visa saldo');
    } else {
      this.$accountBalanceContent.html("".concat(svs.utils.format.Currency(balance), " kr"));
    }
  }
}
$(() => {
  svs.lbUi.balance_bar = new BalanceBar();
});

 })(window);