(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/balance-bar/views/balance-bar.js') >= 0) return;  svs.modules.push('/components/lb-ui/balance-bar/views/balance-bar.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.lb_ui=_svs.lb_ui||{};
_svs.lb_ui.balance_bar=_svs.lb_ui.balance_bar||{};
_svs.lb_ui.balance_bar.templates=_svs.lb_ui.balance_bar.templates||{};
svs.lb_ui.balance_bar.templates.balance_bar = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"mygamingSelfTest",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":7,"column":41},"end":{"line":7,"column":74}}}));
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"gamingResponsibilitySelfTestUrl") || (depth0 != null ? lookupProperty(depth0,"gamingResponsibilitySelfTestUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"gamingResponsibilitySelfTestUrl","hash":{},"data":data,"loc":{"start":{"line":7,"column":82},"end":{"line":7,"column":117}}}) : helper)));
},"5":function(container,depth0,helpers,partials,data) {
    return "      <span class=\"balance-bar-session-time js-bar-session-time\"></span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"templateConfig") : depth0)) != null ? lookupProperty(stack1,"hideMenu") : stack1),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":4},"end":{"line":45,"column":15}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"hasRole")||(depth0 && lookupProperty(depth0,"hasRole"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"hasRole","hash":{"role":"Player"},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":6},"end":{"line":44,"column":18}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"balance-bar-account-item balance-bar-time\" tabindex=\"0\">\n          <span class=\"js-session-time-header fc-grey-500\"></span>\n        </span>\n        <span role=\"button\" class=\"balance-bar-account-item balance-bar-left-border pointer js-balance-toggle balance-bar-toggle\" tabindex=\"0\">\n            "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"icon",{"name":"ui","hash":{"color":"fc-sport pnp-branded-color","icon":"balance","size":"200"},"data":data,"loc":{"start":{"line":37,"column":12},"end":{"line":37,"column":86}}}))
    + "\n          <span class=\"fc-sport pnp-branded-color padding-xs-left js-balance-content\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ssAccountBalance") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":38,"column":86},"end":{"line":38,"column":237}}})) != null ? stack1 : "")
    + "</span>\n        </span>\n        <a href=\""
    + alias3((lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,"transactionsHome",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":40,"column":17},"end":{"line":40,"column":50}}}))
    + "\" class=\"balance-bar-account-item balance-bar-left-border balance-bar-deposit js-balance-bar-deposit\">\n          "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"icon",{"name":"ui","hash":{"color":"fc-sport pnp-branded-color","icon":"deposit","size":"200"},"data":data,"loc":{"start":{"line":41,"column":10},"end":{"line":41,"column":84}}}))
    + "\n          <span class=\"fc-sport pnp-branded-color padding-xs-left\">Sätt in</span>\n        </a>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"templateConfig") : depth0)) != null ? lookupProperty(stack1,"noAccountBalance") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":38,"column":110},"end":{"line":38,"column":212}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return "Visa saldo";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"formatCurrency")||(depth0 && lookupProperty(depth0,"formatCurrency"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ssAccountBalance") : depth0),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":38,"column":167},"end":{"line":38,"column":202}}}))
    + " kr";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"balance-bar js-corporate-info-bar\">\n  <div class=\"balance-bar-libuttons\">\n    <span class=\"balance-bar-libuttons-content margin-md-left\">\n      <a href=\""
    + alias3((lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,"gamingResponsibilityPause",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":4,"column":15},"end":{"line":4,"column":57}}}))
    + "\" aria-label=\"Gå till spelpaus\" class=\"balance-bar-libuttons-link inline-block\"><img alt=\"Spelpaus\" class=\"balance-bar-libuttons-icon\" src=\""
    + alias3((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||alias2).call(alias1,"/images/lb-ui/balance-bar/spelstopp.svg",{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":4,"column":197},"end":{"line":4,"column":254}}}))
    + "\" /></a>\n    </span>\n    <span class=\"balance-bar-libuttons-content margin-xs-left\">\n      <a href=\""
    + ((stack1 = (lookupProperty(helpers,"hasRole")||(depth0 && lookupProperty(depth0,"hasRole"))||alias2).call(alias1,{"name":"hasRole","hash":{"role":"Player"},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":7,"column":15},"end":{"line":7,"column":129}}})) != null ? stack1 : "")
    + "\" aria-label=\"Gå till självtest\" class=\"balance-bar-libuttons-link inline-block js-gaming-test-link\" target=\"_blank\" rel=\"noopener\"><img alt=\"Självtest\" class=\"balance-bar-libuttons-icon\" src=\""
    + alias3((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||alias2).call(alias1,"/images/lb-ui/balance-bar/sjalvtest.svg",{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":7,"column":322},"end":{"line":7,"column":379}}}))
    + "\" /></a>\n    </span>\n    <span class=\"balance-bar-libuttons-content margin-xs-left\">\n      <a href=\""
    + alias3((lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,"myProfileLimits",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":10,"column":15},"end":{"line":10,"column":47}}}))
    + "\" aria-label=\"Gå till spelgränser\" class=\"balance-bar-libuttons-link inline-block\"><img alt=\"Spelbudget\" class=\"balance-bar-libuttons-icon\" src=\""
    + alias3((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||alias2).call(alias1,"/images/lb-ui/balance-bar/spelbudget.svg",{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":10,"column":192},"end":{"line":10,"column":250}}}))
    + "\" /></a>\n    </span>\n    <span class=\"balance-bar-libuttons-content margin-xs-left\">\n      <a href=\""
    + alias3((lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,"gamingResponsibilityPause",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":13,"column":15},"end":{"line":13,"column":57}}}))
    + "\"\n         aria-label=\"Gå till Spelpaus 24 timmar\"\n        class=\"balance-bar-libuttons-link inline-block pointer js-corporate-info-pause24-link\"><img\n          alt=\"Spelpaus 24 timmar\"\n          class=\"balance-bar-libuttons-icon\"\n          src=\""
    + alias3((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||alias2).call(alias1,"/images/lb-ui/balance-bar/spelpaus24.svg",{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":18,"column":15},"end":{"line":18,"column":73}}}))
    + "\" /></a>\n    </span>\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":22,"column":17}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </div>\n  <div class=\"balance-bar-legal fc-grey-600\" tabindex=\"0\">\n    Spel från Svenska Spel Sport &amp; Casino AB\n  </div>\n  <div class=\"balance-bar-right\"></div>\n</div>\n<div class=\"balance-bar-account\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"templateConfig") : depth0)) != null ? lookupProperty(stack1,"hideBalanceBar") : stack1),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":2},"end":{"line":46,"column":13}}})) != null ? stack1 : "")
    + "  </div>\n";
},"useData":true});
Handlebars.partials['components-lb_ui-balance_bar-balance_bar'] = svs.lb_ui.balance_bar.templates.balance_bar;
})(svs, Handlebars);


 })(window);